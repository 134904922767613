<template>
  <v-main>
    <v-container fluid style="height: 100%; min-height: 86vh; width: 100%">
      <v-toolbar extended>
        <v-toolbar-title>{{ currentTest.description }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <div v-if="this.currentTest.active" class="mt-4">
          <span>{{ $t("signalTest.updateTimer") }}</span>
          <timed-progress :time="10" v-on:event-update="updateTest" />

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                color="accent"
                v-on="on"
                @click.stop="stopCurrentTest()"
                style="margin-top: 0.5rem"
              >
                <!-- Open Dialog to create test -->
                {{ $t("signalTest.endTest") }}
              </v-btn>
            </template>
            <span>{{ $t("signalTest.endTest") }}</span>
          </v-tooltip>
        </div>
        <div v-else>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                color="error"
                v-on="on"
                @click="confirmDelete = true"
                style="margin-top: 0.5rem"
                v-if="permitted('SignalTest.Delete')"
              >
                <!-- Open Dialog to create test -->
                {{ $t("common.delete") }}
              </v-btn>
            </template>
            <span>{{ $t("common.delete") }}</span>
          </v-tooltip>
          <v-dialog v-model="confirmDelete" max-width="450">
            <v-card>
              <v-card-title class="headline">
                {{ $t("common.verifyDelete") }}
              </v-card-title>

              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn
                  color="primary lighten-1"
                  text
                  @click="confirmDelete = false"
                  >{{ $t("common.close") }}</v-btn
                >

                <v-btn
                  color="primary"
                  text
                  @click="removeTest(currentTest.signalTestId)"
                  >{{ $t("common.yes") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <template v-slot:extension>
          <v-tabs v-model="tab" centered>
            <v-tabs-slider></v-tabs-slider>

            <v-tab v-for="item in tabItems" :key="item.key" :value="item.key">
              {{ item.label }}
            </v-tab>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="i in tabItems" :key="i.key">
          <v-card v-if="i.key == 'data'">
            <v-row>
              <v-col cols="12" sm="12" md="4" offset-md="2">
                <v-card elevation="12">
                  <v-card-title>{{
                    $t("signalTest.fields.avgRssi")
                  }}</v-card-title>
                  <v-card-text>
                    <span class="headline">
                      {{ rssiAvg }}
                    </span>
                  </v-card-text>
                  <v-card-text>
                    <v-sparkline
                      height="50"
                      v-if="rssis.length > 0"
                      :value="rssis"
                      :gradient="['green', 'yellow', 'red']"
                      :smooth="10"
                      :padding="8"
                      :line-width="2"
                      stroke-linecap="round"
                      :fill="false"
                      type="trend"
                      :auto-line-width="false"
                      auto-draw
                    ></v-sparkline>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-card elevation="12">
                  <v-card-title>{{
                    $t("signalTest.fields.avgSnr")
                  }}</v-card-title>
                  <v-card-text>
                    <span class="headline">
                      {{ snrAvg }}
                    </span>
                  </v-card-text>
                  <v-card-text>
                    <v-sparkline
                      height="50"
                      v-if="snrs.length > 0"
                      :value="snrs"
                      :gradient="['green', 'yellow', 'red']"
                      :smooth="10"
                      :padding="8"
                      :line-width="2"
                      stroke-linecap="round"
                      :fill="false"
                      type="trend"
                      :auto-line-width="false"
                      auto-draw
                    ></v-sparkline>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-data-table
                  class="overflow-datatable-scroll"
                  :headers="[
                    {
                      text: $t('signalTest.fields.data.rssi'),
                      value: 'rssi',
                    },
                    {
                      text: $t('signalTest.fields.data.snr'),
                      value: 'snr',
                    },
                    {
                      text: $t('signalTest.fields.data.lat'),
                      value: 'lat',
                    },
                    {
                      text: $t('signalTest.fields.data.lng'),
                      value: 'lng',
                    },
                    {
                      text: $t('signalTest.fields.data.createdAt'),
                      value: 'createdAt',
                      dataType: 'Date',
                    },
                  ]"
                  sort-by="createdAt"
                  :items="testData"
                  dense
                  disable-pagination
                  hide-default-footer
                >
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ humanDate(item.createdAt, "yyyy-MM-dd HH:mm:ss") }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <GmapMap
        ref="gmap"
        :center="{ lat: 57, lng: 16 }"
        :zoom="5"
        map-type-id="terrain"
        :options="{ minZoom: 5, maxZoom: 19 }"
        style="height: 100%; width: 100%"
        class="elevation-11"
      >
        <GmapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          :draggable="false"
          :label="m.rssi.toString()"
          :icon="getIcon(m.rssi)"
          @click="(e) => openInfoWindow(e, m)"
        />

        <GmapMarker
          :key="m.macAddress"
          v-for="m in gatewayMarkers"
          :position="m.position"
          :clickable="true"
          :draggable="false"
          :label="{
            text: m.gatewayName,
            fontSize: '13px',
          }"
          :icon="gatewayMarkerIcon"
          @click="togglePolyLines(m)"
        />

        <GmapMarker
          :key="'marker' + i"
          v-for="(m, i) in hiddenMarkers"
          :position="m.position"
          :clickable="true"
          :draggable="false"
          :icon="hiddenMarker"
          :label="{
            text: m.distance,
            fontSize: '15px',
            color: '#3e3e3e',
            background: '#fff',
          }"
        />

        <GmapMarker
          :key="'tmarker' + i"
          v-for="(m, i) in hiddenMarkersTest"
          :position="m.position"
          :clickable="true"
          :draggable="false"
          :icon="hiddenMarker"
          :label="{
            text: m.distance,
            fontSize: '15px',
            color: '#3e3e3e',
            background: '#fff',
          }"
        />

        <GmapPolyline
          :key="i + p + 't'"
          v-for="(p, i) in testPolyLines"
          v-bind:path="p"
          v-bind:options="{ strokeColor: '#009050', strokeOpacity: 0.3 }"
        />

        <GmapPolyline
          :key="i + p"
          v-for="(p, i) in polyLines"
          v-bind:path="p"
          v-bind:options="{ strokeColor: '#000050', strokeOpacity: 0.3 }"
        />

        <GmapInfoWindow
          :options="{
            width: 0,
            height: -25,
          }"
          :opened="infoWindowStatus"
          :position="
            currentMarker ? currentMarker.position : { lat: 0, lng: 0 }
          "
          @closeclick="closeInfoWindow"
        >
          <v-container
            v-if="currentMarker != null"
            style="width: 100%; height: 100%"
          >
            <v-card flat>
              <v-card-text>
                <h3>
                  {{ $t("signalTest.fields.data.rssi") }}
                </h3>
                <p class="headline-3">{{ currentMarker.rssi }}</p>

                <h3 class="headline-2">
                  {{ $t("signalTest.fields.data.snr") }}
                </h3>
                <p class="headline-3">{{ currentMarker.snr }}</p>

                <h3 class="headline-2">
                  {{ $t("signalTest.fields.data.createdAt") }}
                </h3>
                <p class="headline-3">
                  {{ humanDate(currentMarker.createdAt) }}
                </p>
              </v-card-text>
            </v-card>
          </v-container>
        </GmapInfoWindow>
      </GmapMap>
    </v-container>
  </v-main>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { gmapApi } from "vue2-google-maps";
import Markers from "@/_helpers/googleMapsHelper.js";
import TimedProgress from "../common/TimedProgress.vue";
import { Roles } from "@/_helpers/Role";

export default {
  components: { TimedProgress },
  name: "PeekSignalTest",

  data() {
    return {
      testData: [],
      currentTest: {},
      map: null,
      tab: null,
      bounds: null,
      infoWindowStatus: false,
      currentMarker: null,
      confirmDelete: false,
      gatewayMarkerIcon: Markers.gateway,
      hiddenMarker: Markers.hidden,
      showPolylines: false,

      tabItems: [
        { label: this.$t("signalTest.tab.map"), key: "map" },
        { label: this.$t("signalTest.tab.data"), key: "data" },
      ],

      markers: [],
      gatewayMarkers: [],
      hiddenMarkers: [],
      hiddenMarkersTest: [],
      polyLines: [],
      testPolyLines: [],
    };
  },

  computed: {
    ...mapState("gateways", ["gateways"]),
    ...mapState("tagData", ["meta"]),

    Roles() {
      return Roles;
    },

    google: gmapApi,

    snrAvg() {
      let count = 0;
      let avg = 0;
      for (var i in this.testData) {
        if (this.testData[i].snr != null && this.testData[i].snr != undefined) {
          avg += this.testData[i].snr;
          count++;
        }
      }

      return (avg / count).toFixed(2);
    },

    snrs() {
      let snrs = [];
      for (var i in this.testData) {
        if (this.testData[i].snr != null) {
          snrs.push(this.testData[i].snr);
        }
      }

      return snrs;
    },

    rssiAvg() {
      var avg = 0;
      for (var i in this.testData) {
        avg += this.testData[i].rssi;
      }

      return (avg / this.testData.length).toFixed(2);
    },

    rssis() {
      let rssis = [];
      for (var i in this.testData) {
        rssis.push(this.testData[i].rssi);
      }

      return rssis;
    },
  },

  methods: {
    ...mapActions("gateways", ["getGateways"]),
    ...mapActions("signalTests", ["peek", "getTest", "endTest", "deleteTest"]),

    async stopCurrentTest() {
      await this.endTest(this.currentTest.signalTestId);
      this.currentTest = await this.getTest(this.currentTest.signalTestId);
      this.testData = await this.peek(this.currentTest.signalTestId);
    },

    async updateTest() {
      this.testData = await this.peek(this.currentTest.signalTestId);
      this.currentTest = await this.getTest(this.currentTest.signalTestId);
      await this.$gmapApiPromiseLazy();

      // Massage the information from the testData
      for (var i = 0; i < this.testData.length; i++) {
        // Create latnlng
        if (!this.existInMarker(this.testData[i])) {
          let temp = this.testData[i];
          temp.position = new this.google.maps.LatLng(temp.lat, temp.lng);
          this.markers.push(temp);
        }
      }
    },

    existInMarker(tData) {
      for (var i in this.markers) {
        if (tData.createdAt === this.markers[i].createdAt) return true;
      }

      return false;
    },

    getDistance(coords1, coords2) {
      var lon1 = coords1.lng();
      var lat1 = coords1.lat();

      var lon2 = coords2.lng();
      var lat2 = coords2.lat();

      var R = 6371; // km

      var x1 = lat2 - lat1;
      var dLat = (x1 * Math.PI) / 180;
      var x2 = lon2 - lon1;
      var dLon = (x2 * Math.PI) / 180;
      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((lat1 * Math.PI) / 180) *
          Math.cos((lat2 * Math.PI) / 180) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      var d = R * c;

      return d.toFixed(0).toString() + " Km";
    },

    togglePolyLines(gateway) {
      // The gateway has the position of the start of the poly line and all the test
      this.polyLines = [];
      this.hiddenMarkers = [];
      this.showPolylines = !this.showPolylines;
      if (this.showPolylines) {
        for (var i in this.markers) {
          this.polyLines.push([gateway.position, this.markers[i].position]);
          var lat =
            (gateway.position.lat() + this.markers[i].position.lat()) / 2;
          var lng =
            (gateway.position.lng() + this.markers[i].position.lng()) / 2;

          let dist = this.getDistance(
            gateway.position,
            this.markers[i].position
          );

          this.hiddenMarkers.push({
            testPoly: false,
            distance: dist,
            position: new this.google.maps.LatLng(lat, lng),
          });
        }
      }
    },

    initMap() {
      this.bounds = null;
      if (this.bounds == null && this.testData.length > 0) {
        let bounds = new this.google.maps.LatLngBounds();
        for (var i = 0; i < this.testData.length; i++) {
          // Create latnlng
          let temp = this.testData[i];
          temp.position = new this.google.maps.LatLng(temp.lat, temp.lng);
          if (this.markers.length >= this.testData.length) {
            this.markers.push(temp);
          }

          bounds.extend(temp.position);
        }
        this.bounds = bounds;
      }

      if (this.bounds != null) {
        this.$refs.gmap.$mapPromise.then((map) => {
          map.fitBounds(this.bounds);
        });
      }
    },

    getIcon(rssi) {
      if (rssi >= -120 && rssi < -100) return Markers.yellow;
      if (rssi < -120) return Markers.red;
      if (rssi > -100) return Markers.green;
      return Markers.green;
    },

    async removeTest(test) {
      await this.deleteTest(test);
      this.confirmDelete = false;
      this.$router.push("/signaltest");
    },

    closeInfoWindow() {
      this.infoWindowStatus = false;
    },

    async toggleTestPolyLines(test) {
      // From the test we can get best gateway and so on
      let bestGateway = null;
      if (test.meta != "") {
        var m = JSON.parse(test.meta);
        if (m) bestGateway = m;
      }

      this.testPolyLines = [];
      this.hiddenMarkersTest = [];
      this.showPolylines = true;
      for (var i in this.gatewayMarkers) {
        let isBest =
          bestGateway != null &&
          this.gatewayMarkers[i].macAddress == bestGateway.bestGateway;
        this.testPolyLines.push([
          this.gatewayMarkers[i].position,
          test.position,
        ]);
        var lat =
          (this.gatewayMarkers[i].position.lat() + test.position.lat()) / 2;
        var lng =
          (this.gatewayMarkers[i].position.lng() + test.position.lng()) / 2;

        let dist = this.getDistance(
          this.gatewayMarkers[i].position,
          test.position
        );

        this.hiddenMarkersTest.push({
          testPoly: true,
          best: isBest,
          distance: dist,
          position: new this.google.maps.LatLng(lat, lng),
        });
      }
    },

    async openInfoWindow(event, marker) {
      if (event.domEvent.ctrlKey) {
        // Remove the poly lines from the test to gateways
        this.testPolyLines = [];
        this.hiddenMarkersTest = [];
        return;
      }

      await this.toggleTestPolyLines(marker);
      this.currentMarker = marker;
      this.infoWindowStatus = !this.infoWindowStatus;
    },
  },

  async mounted() {
    if (this.$route.params.id === undefined) this.$router.push("/signaltest");

    let testId = this.$route.params.id;
    this.testData = await this.peek(testId);
    this.currentTest = await this.getTest(testId);
    await this.getGateways();
    await this.$gmapApiPromiseLazy();

    // Massage the information from the testData
    for (var i = 0; i < this.testData.length; i++) {
      // Create latnlng
      let temp = this.testData[i];
      temp.position = new this.google.maps.LatLng(temp.lat, temp.lng);
      this.markers.push(temp);
    }

    this.gatewayMarkers = [];
    for (var i = 0; i < this.currentTest.gateways.length; i++) {
      let temp = this.currentTest.gateways[i];
      if (temp.longitude && temp.latitude) {
        temp.position = new this.google.maps.LatLng(
          temp.latitude,
          temp.longitude
        );

        this.gatewayMarkers.push(temp);
      }
    }

    this.$nextTick(() => {
      this.initMap();
    });
  },

  beforeDestroy() {
    this.bounds = null;
    this.markers = [];
  },
};
</script>
